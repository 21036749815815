import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs as SiteBreadcrumbs } from '@lxlabs/change-ad-components';

const Breadcrumbs = ({ links, sidePadding, blacklist }) => {
  // This code maps the Gatsby plugin to match the object property for the
  // component library component. Once done, it will only return objects which
  // aren't on the blacklist.
  const linkMap = links.map((x) => ({
    url: x.pathname,
    label: x.crumbLabel,
  })).filter((x) => !blacklist.includes(x.url));

  return <SiteBreadcrumbs sidePadding={sidePadding} links={linkMap} />;
};

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  sidePadding: PropTypes.bool,
  blacklist: PropTypes.arrayOf(PropTypes.string),
};

Breadcrumbs.defaultProps = {
  sidePadding: true,
  blacklist: [],
};

export default Breadcrumbs;
